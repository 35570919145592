.main-content {
  border: 1px solid rgba(245, 237, 237, 0.318);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: #ffffff79;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.353);
  display: flex;
  flex-direction: column;
  gap: 15px;
  img {
    display: inline-block;
    margin-right: 20px;
  }
}

#form-search {
  input {
    outline: none;
    &:focus-visible {
      outline: none;
    }
  }
}

.table-data {
  border-radius: 8px;
  overflow: hidden;
}

.suggest-search {
  position: absolute;
  margin: 0;
  width: calc(100% - 42px);
  z-index: 1;
  box-shadow: 2px 2px 2px grey;
  max-height: 250px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0.2em;
  }
}

.input-search {
  position: relative;
}
.change-scroll {
  &::-webkit-scrollbar {
    width: 0.2em;
  }
}

.bg-green {
  background-color: rgba(135, 169, 148, 1);
  color: black;
  font-size: 1.2em;
}
.bg-white-custom {
  background-color: #ffffff8e;
  color: black;
  font-size: 1.2em;
}
table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  > tr {
    font-size: 1.3em;
    font-weight: bold;
  }
}
.li-content {
  color: black;
}

@media screen and (max-width: 768px) {
  .main-dictionary {
    padding: 20px;
    > section {
      padding: 10px;
    }
  }
}
@media screen and (max-width: 500px) {
  .main-dictionary {
    padding: 10px;
    > section {
      padding: 5px;
      gap: 10px;
      h1 {
        padding: 20px;
      }
    }
  }
  .main-content {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .bg-green {
    font-size: 0.9em;
    tr,
    td,
    th {
      padding: 5px;
    }
  }
  .bg-white-custom {
    font-size: 0.9em;
    tr,
    td,
    th {
      padding: 5px;
    }
  }
  table thead {
    > tr,
    th {
      font-size: 1em;
      font-weight: bold;
      padding: 5px !important;
    }
  }
}
body {
  min-width: 290px;
}
