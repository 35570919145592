@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

body {
    color: rgb(var(--foreground-rgb));
    background: radial-gradient(
            circle,
            rgba(6, 66, 31, 0.8) 0%,
            rgba(204, 222, 210, 0.5) 25%,
            rgba(204, 222, 210, 0.5) 50%,
            rgba(204, 222, 210, 0.5) 75%,
            rgba(6, 66, 31, 0.8) 100%
        ),
        url('./assets/images/bangkok.jpg');

    background-size: cover;
    background-position: center right;
}
